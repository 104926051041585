export const AlamedaIconSvg = ({ fill = "#ffffff" }: { fill?: string }) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2414 31.0627C23.9429 31.2729 30.8586 24.703 31.0682 16.9242C31.2777 8.67232 24.9908 2.31262 17.5512 1.78702C8.80178 1.10375 1.99088 7.83137 1.67654 15.6627C1.30979 24.1248 7.70155 30.7999 16.2414 31.0627ZM32.6923 16.3986C32.6923 25.5439 25.4099 32.7446 16.2414 32.692C7.28242 32.692 0.0523915 25.3862 0 16.3986C0 7.30577 7.28242 0 16.3985 0C25.3575 0 32.6399 7.25321 32.6923 16.2409C32.6923 16.2934 32.6923 16.346 32.6923 16.3986Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0617 18.6586L16.3465 10.3016C15.3511 11.9835 14.4604 13.5603 13.5697 15.0845C12.7839 16.5036 11.998 17.9227 11.1597 19.3418C11.0025 19.6046 10.8978 19.9726 10.6882 20.0251C10.2167 20.1828 9.74516 20.2354 9.22125 20.2879C9.16885 19.8674 9.01168 19.3944 9.16885 19.079C11.1597 15.4524 13.1506 11.8784 15.2463 8.30435C15.613 7.67363 16.3989 7.46339 17.0276 7.83131C17.2371 7.93642 17.3943 8.14667 17.4991 8.30435C19.5424 11.8258 21.5332 15.3999 23.5241 18.9214C24.1004 19.9726 23.8385 20.3405 22.6335 20.3405C19.9615 20.3405 17.2372 20.3405 14.5128 20.2879C14.0413 20.2879 13.203 19.92 13.203 19.7098C13.203 18.8688 13.7269 18.4483 14.67 18.5009C16.7656 18.7111 18.8089 18.6586 21.0617 18.6586ZM16.2417 24.8606C14.146 24.8606 12.1028 24.8606 10.0071 24.8606C9.32603 24.8606 8.80212 24.7555 8.74973 23.9145C8.69733 22.8633 9.43081 23.0736 10.0071 23.0736C14.1984 23.0736 18.3898 23.0736 22.6335 23.0736C23.2622 23.0736 23.8909 23.0736 23.8909 23.9671C23.8909 24.8606 23.3146 24.8606 22.6335 24.8606C20.4854 24.808 18.3374 24.8606 16.2417 24.8606Z"
      fill={fill}
    />
  </>
);
