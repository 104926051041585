import { EpochRow } from "../../09/registry10";

export const HARDCODED_DEVNET_DATA: EpochRow[] = [
  {
    globalId: "mainnet_income_call_marinade",
    product: "mSOL-240-CALL-1640937599",
    startEpoch: 1640304000,
    endEpoch: 1640937599,
    balanceStart: 22212,
    balancePnl: 22212 * 0.0117,
    realizedPnl: 22212 * 0.0117,
    spotPriceAtAuctionEnd: 1,
    txid: "2Ey3sFSgvQSMhTf5K41HHQQQRDQm7EaWv9LrfJNfJo9WVr3m1bfeQqBDiT2sWekModPCHYtVpZacS77gnT9w1Dgd",
  },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1638914399",
  //   startEpoch: 1638828000,
  //   endEpoch: 1638914399,
  //   balanceStart: 63217.89,
  //   balancePnl: 423.39,
  //   realizedPnl: 423.39,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "5Q3jvpW19nUX5KzFs29xLFwdEXtxqb7iFz4EtHVmWKXRgkcRpVNnXj6ZAbGUQb8jx36b9TsKD2vQtXYbW9RwcChT",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1638827999",
  //   startEpoch: 1638741600,
  //   endEpoch: 1638827999,
  //   balanceStart: 60527.16,
  //   balancePnl: 303.49,
  //   realizedPnl: 303.49,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "2royGiyk7H483LzohWsj4YQLJoJU2HUUQ9vgK71AMWrRVrcnn4a1wfL27uKM3SvMwm9K6vgRVEedPPqAjFMufaF2",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1638741599",
  //   startEpoch: 1638655200,
  //   endEpoch: 1638741599,
  //   balanceStart: 36637.18,
  //   balancePnl: 307.29,
  //   realizedPnl: 307.29,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "2z8eXxd4gT3LNHaVfJ1ckYd23MjfaWDtwmANXUcawNG19EngHTwAQpaMTo6jybu9X2APhZDGLybYwEgfmLMuuWQe",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1638655199",
  //   startEpoch: 1638568800,
  //   endEpoch: 1638655199,
  //   balanceStart: 24885.74,
  //   balancePnl: 811.99,
  //   realizedPnl: 811.99,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "4Ga7PDbLBv9ajdnn9KjvL7YovdvfBU1REqKt8FbriHyD2GoSsMmnX4fFjRxdo7nvtWEs2JNocEgj4gyDxdLwN43Y",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1638568799",
  //   startEpoch: 1638482400,
  //   endEpoch: 1638568799,
  //   balanceStart: 22709.5,
  //   balancePnl: 659.39,
  //   realizedPnl: 659.39,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "2NyF73yxkJt1qygKKd1gMCSgTahDTxz1vg1oL3VUcyXC5NnvU7qaEQ4z3mhsw7F7KpFC8eYDqnm9AY6TuU6GPzSU",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1638482399",
  //   startEpoch: 1638396000,
  //   endEpoch: 1638482399,
  //   balanceStart: 13227.24,
  //   balancePnl: 417.99,
  //   realizedPnl: 417.99,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "2MwV3QuFupYbEktYPZnz8wdAsPo2UEWL48SWArke653qAFc6ezMEo6TSz89AE9KJqCuvZxLDk5cfAMneesCSLrfd",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1638395999",
  //   startEpoch: 1638309600,
  //   endEpoch: 1638395999,
  //   balanceStart: 11900.75,
  //   balancePnl: 418.02,
  //   realizedPnl: 418.02,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "5HWiTcubKmsvKpNT9DzBi78G5CgePZsTBATF5s6evkH14KXmGY9rJQYCwaWS6sAv5VNa5e7pdVfA92k3A3wRbDu4",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1638309599",
  //   startEpoch: 1638223200,
  //   endEpoch: 1638309599,
  //   balanceStart: 11202.71,
  //   balancePnl: 485.99,
  //   realizedPnl: 485.99,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "eiBv4JpL1dbebSEjGzVHAF6yM6rL9kyFn1VhZiZCoc4RG1bTdvxfRyscv7FRK2saXKp425W1UrfG8aMiU7DMxwx",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1638136799",
  //   startEpoch: 1638050400,
  //   endEpoch: 1638136799,
  //   balanceStart: 9924.41,
  //   balancePnl: 477.7,
  //   realizedPnl: 477.7,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "5dWZwurJM95Yysyjt3TKTZjx8R8PYiianuLdwipv8mYo9TQhf2pftAj9ruXtQaaxoznnoJZWkwJUffrHWWvHUgo9",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1638050399",
  //   startEpoch: 1637964000,
  //   endEpoch: 1638050399,
  //   balanceStart: 8560.13,
  //   balancePnl: 407.42,
  //   realizedPnl: 407.42,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "4THba9VX4jB9E83gA3o3i5R9QX3aum6nJSz38LXagcSro3GDDkmMqLf4v2MCPAoDGNUA6TPvpsTpVAPB3pbsqAAL",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1637963999",
  //   startEpoch: 1637877600,
  //   endEpoch: 1637963999,
  //   balanceStart: 4880.24,
  //   balancePnl: 196.76,
  //   realizedPnl: 196.76,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "xV4AWiRrRmSEBKs6mmikCEurAiZx1fB9XPaQsCyo6o54DJrGwBvC9hQeX57NeRLXqPmA1xy5gQLhSVpiDJei5oq",
  // },
  // {
  //   globalId: "mainnet_income_call_btc",
  //   product: "BTC-75000-CALL-1637877599",
  //   startEpoch: 1637791200,
  //   endEpoch: 1637877599,
  //   balanceStart: 12168570.12 / 60000 / 0.04,
  //   balancePnl: 12168570.12 / 60000,
  //   realizedPnl: 12168570.12 / 60000,
  //   spotPriceAtAuctionEnd: 1,
  //   txid: "5BR9h937Y3h1DJayUa8iT8eKoZEUAjKCo5Lmk9KLMCJ5ns1qL1sYWi9MPzzaKyrL9kSdhNinKAwGh6QGdBdJnVJn",
  // },
];
